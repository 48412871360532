@import "variaveis";
@import "config";
@import "helpers";
@import "stellarnav";
@import "owl.carousel";
@import "owl.theme";
@import "remodal";
@import "noticias-modal";
@import "header";
	@import "menu_principal";
@import "index";
@import "contato";
@import "noticia";
@import "areas";
@import "footer";
// @import "botoes_paginacao";
@import "helpers";
@import "tabs";
