.header__wrap{
    align-content: center;
    background-color: rgba(0, 0, 0, .2);
    display: flex;
    height: 75px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
    -webkit-transition: background-color 100ms ease-in;
    -ms-transition: background-color 100ms ease-in;
    transition: background-color 100ms ease-in;
    transition: all 100ms ease-in;

    @media #{$mq-mobile} {
        background: $preto;
    }

    .conteudo{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .header__logo{
        transition: width 340ms ease-out;

        // img{
        //     width: 243px;
        //     transition: width 340ms ease-out;
        // }
    } 

    &.raso{
        // height: 60px;
        background-color: rgba(0, 0, 0, 1);
        
        -webkit-transition: background-color 100ms ease-in;
        -ms-transition: background-color 100ms ease-in;
        transition: background-color 100ms ease-in;
        
        .header__logo{

            // img{
            //     width: 133px;
            // }
        }
    }

    &.modal-aberto{
        position: absolute;
    }

	//atraso pra evitar pulo lateral no header
    &.oculto{
        opacity: 0;
    }
}   


    .header__menu {
        height: 100%;

        align-items: center;
        display: flex;

        @media #{$mq-tablet} {
            display: none;
        }

        @media #{$mq-mobile} {
            display: none;
        }

        ul {
            height: 100%;
            list-style: none;
           
            align-items: center;
            display: flex;
            justify-content: space-around;
             
            @media #{$mq-mobile} {
                flex-direction: column;
                justify-content: flex-start;
                padding: 80px 0;
            }
                    
                li {
                    height: 100%;
                    padding: 0 15px;
                
                    align-items: center;
                    display: flex;
                    
                    &:first-child {
                        padding-left: 0;
                    }
                    
                    &:last-child {
                        padding-right: 0;
                    }
                }
                
            .call-btn-mobile.third{
                display: none;
            }

            .location-btn-mobile.third{
                display: none;
            }
            
            .close-menu{
                border-radius: 100%;
                color: white;
                display: none;
                font-weight: 600;
                font-size: 30px;
                justify-content: center;
                height: 50px;
                padding: 0;
                position: absolute;
                right: 10px;
                top: 5px;
                width: 50px;

                @media #{$mq-mobile} {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                span{
                    display: none;
                }
            }
        }

        & > a{
            @media #{$mq-mobile} {
                font-size: 30px;
            }
        }

        a {
            color: $branco;
            font-size: 13px;
            font-weight: $f-regular;
            height: 100%;
            position: relative;

            align-items: center;
            display: flex;

            @media #{$mq-tablet}{
            }
            
            @media #{$mq-mobile} {
                font-size: 30px;
                height: 80px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }

            &:before {
                top: 0;
                content: "";
                height: 2px;
                width: 0%;
                background-color: $azul-claro;
                transition: 0.3s ease all 1.5ms;

                @media #{$mq-mobile} {
                    height: 10px;
                }
            }

            &:hover{
                &:before {
                    width: 100%;
                    transition: 0.3s ease all;

                    @media #{$mq-mobile} {
                        display: none;
                    }
                }
            }
            
            &.menu-toggle{
                font-size: 0;
            }

            span.bars{
                span{
                    font-size: 0;
                }
            }

        }
    }

    .header__menu-mobile{
        background-color: $preto;
        display: none;
        height: 100vh;
        position: absolute;
        right: 0px;
        top: 0;
        width: 50%;
        transition: all 300ms ease-out;

        @media #{$mq-tablet} {
            width: 40%;
            right: 0;
            display: block;
        }

        @media #{$mq-mobile} {
            display: block;
        }

        ul{
            border-top: 1px solid $branco;
            height: 100%;
            list-style: none;
        }

        li{
            height: auto;
            margin: 25px 0px;
            text-align: right;

            a{
                color: $branco;
                cursor: pointer;
                display: block;
                font-size: 26px;
                font-weight: $f-regular;
                padding-right: 15px;
                width: 100%;
            }
        }
        
        .header__menu-mobile-fechar{
            border-radius: 100%;
            
            text-align: center;
            margin-top: 17px;
            margin-bottom: 17px;
            margin-bottom: 15px;
            width: 60px;

            img {
                height: auto;
                width: 25px;
                border-radius: 50%;
            }
        }
        
        &.menu-mobile-fechado{
            transform: translate3d(320px, 0, 0);
        }
    }

    .botao-menu-mobile-abrir{
        display: none;

        @media #{$mq-tablet} {
            display: block;
            
            img {
                width: 45px !important;
                height: auto;
            }
        }

        @media #{$mq-mobile} {
            display: block;
        }

        img{
            width: 100%;
            margin: 0 auto;
        }
    }