/*-------------------------------
        NOTICIAS MODAL
-------------------------------*/ 

.modal-noticias.modal-noticias.modal-noticias{
    max-width: 100vw;
    height: 100vh;
    margin-bottom: 0;
    background-color: #efefef;

    @media #{$mq-tablet}{
    }
    
    @media #{$mq-mobile} {
        
    }
    
    .conteudo{
        padding: 15px 95px 15px 130px;
        background-color: #fff;
        border-radius: 5px;
        height: 100%;

        
        @media #{$mq-tablet}{
            width: 100%;
        }
        
        @media #{$mq-mobile} {
            padding: 15px 15px 15px 40px;
        }
    }
    
    .scroll-wrap{
        overflow-y: auto;
        height: 100%;
        padding-right: 35px;

        @media #{$mq-tablet}{
        }
        
        @media #{$mq-mobile} {
            
        }

        /* Let's get this party started */
        &::-webkit-scrollbar {
            height: 12px;
            width: 4px;
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
            background: $cinza; 
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $preto; 
        }
        // &::-webkit-scrollbar-thumb:window-inactive {
        //     background: $cinza; 
        // }
    }
}

    .modal-noticias__header-bloco{
        height: auto;
        padding-top: 50px;
        margin-bottom: 50px;

        @media #{$mq-mobile} {
            margin-bottom: 0;
        }
    }
    
        .modal-noticias__titulo{
            font-size: 50px;
            text-align: center;
        }
        .modal-noticias__data{
            font-size: 18px;
            text-align: center;
            padding-left: 4px;
            margin-top: 20px;

            @media #{$mq-mobile} {
                font-size: 25px;
            }
        }

    .modal-noticias__img-bloco{
        margin-bottom: 35px;
    }

    .modal-noticias__noticia-bloco{
        padding: 15px;
        margin-bottom: 35px;
        
        ul{
            text-align: left;
            width: 50%;
            margin: 0 auto;
            margin-top: 50px;

            @media #{$mq-mobile} {
                width: 90%;
            }
        }

        li{
            font-size: 20px;

            @media #{$mq-mobile} {
                font-size: 30px;  
                height: 80px;
            }
        }
    }

        .modal-noticias__noticia-texto{
            color: $preto;
            font-size: 20px;
            text-align: justify;
            line-height: 30px;
            white-space:pre-wrap;
             
            @media #{$mq-mobile} {
                font-size: 30px;  
                line-height: 35px;
            }

        }

#modal-area-familia,
#modal-transito,
#modal-consumidor,
#modal-preventiva{
   p{
       white-space: initial;
   }
}