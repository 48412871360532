/*-------------------------------
        BANNER PRINCIPAL
-------------------------------*/ 


.home-banner__wrap {
    overflow: hidden;
    width: 100%;

    @media #{$mq-mobile} {
        margin-top: 75px;
    }

    .banner-imagem {
        height: auto;
        width: 100%;
    }
    
    .cycle-slideshow {
        height: auto;
        position: relative;
        z-index: 0;

        .cycle-pager {
            @media #{$mq-tablet} {
                bottom: 40%;
            }
        }

        @media #{$mq-mobile} {
            height: auto;
            overflow: hidden;
        }

        a {
            overflow: hidden;
            width: 100% !important;

            figure {
                background-position: center;
                height: auto;
                width: 100%;

                @media #{$mq-mobile} {
                    height: auto;
                    width: 100%;
                }
            }
        }
    }

    .cycle-pager { 
        bottom: 50%;
        overflow: hidden;
        position: absolute;
        right: 75px;
        text-align: center;
        z-index: 101; 
 
        @media #{$mq-mobile} {
            bottom: 35%;
        }

        span { 
            border: 1px solid $branco;
            border-radius: 50%;
            color: transparent;
            cursor: pointer;
            display: inline-block;
            font-family: arial;
            font-size: 50px;
            height: 14px;
            margin-bottom: 15px;
            line-height: 14px; 
            width: 14px;

            display: flex;
            align-items: center;
            justify-content: center;

            @media #{$mq-mobile} {
                height: 20px;
                width: 20px;
            }

            &.cycle-pager-active {
                color: $branco;
            }
        }

        & > * {
            cursor: pointer;
        } 
    }

    .home-banner__social {
        bottom: 70px;
        position: absolute;
        width: 100%;
        z-index: 101;
        
        display: flex;
        align-items: center;
        justify-content: center;

        @media #{$mq-tablet} {
            bottom: 30px;
        }

        @media #{$mq-mobile} {
            bottom: 15px;
        }

        a {
            width: auto !important;
        }
            
            img {
                margin: 0 5px;

                @media #{$mq-mobile} {
                    margin: 0 10px;
                }
            }
    }
}

    .js-carrossel-banner {
        width: 100%;
    }
/*-------------------------------
    A EMPRESA
-------------------------------*/ 
 
.home-empresa__wrap {
    background: #f4f7f9;
    padding-top: 80px;
    padding-bottom: 50px;

    .titulo-padrao {

        img {
            background: #f4f7f9;
        }
    }

    .empresa__conteudo p {
        color: $azul;  
        font-weight: 500;
        margin-top: 10px; 
        text-align: justify;
        line-height: 30px;

        span {
            font-weight: 700;
        }

        @media #{$mq-mobile} {
            font-size: 24px;
            margin-top: 25px;
        }
        
    }

    @media #{$mq-mobile} {
        
    }

    .conteudo {
        height: 100%;

        @media #{$mq-tablet} {
            padding-bottom: 60px;
        }
        @media #{$mq-mobile} {
            flex-direction: column;
            padding-bottom: 60px;
        }
    }
}

        



/*-------------------------------
        PRODUTOS
-------------------------------*/ 

.home-produtos__wrap {
    display: table;
    padding-top: 100px;
    
    .titulo-padrao {
        img {
            background: #fff;
        }
    }
}
    
    .home-produtos__sub-titulo {
        color: $azul;
        font-size: 18px;
        font-weight: $f-semi-bold;
        text-align: center;
        width: 100%;
    }

    .home-produto__conteudo {
        margin-top: 83px;

        .tabs {
            display: flex;
            justify-content: center;
        }

        .tab-content {
            padding: 0;
        }

        // Cada imagem do grid
        .grid {
            max-width: 100% !important;
            padding: 0;
            
            figure {
                height: auto !important;
                margin: 0 !important;
                max-height: auto !important;
                max-width: 25% !important;
                overflow: hidden;
                width: 25% !important;

                @media #{$mq-mobile} {
                    height: 50% !important;
                    max-height: 50% !important;
                    max-width: 50% !important;
                    min-width: 50% !important;
                    width: 50% !important;
                    float: left;
                }

                figcaption {
                    @media #{$mq-mobile} {
                        padding: 20px;
                    }
                }
            }
        }

        ul.tabs li {
            background: $branco;
            border-radius: 10px;
            color: $azul;
            font-size: 13px;
            font-weight: $f-bold;
            margin: 0 10px;
            margin-bottom: 40px;
            padding: 8px 20px;

            @media #{$mq-mobile} {
                font-size: 18px;
            }
        }

        ul.tabs li.current {
            background: $verde;
            color: $branco; 
            font-size: 13px;
            font-weight: $f-bold;
            margin: 0 10px;
            margin-bottom: 40px;
            padding: 8px 20px;

            @media #{$mq-mobile} {
                font-size: 18px;
            }
        }
    }
    
    // Efeito para imagem ajustada
    figure.effect-apollo figcaption::before {
        top: -30px !important;
    }
    
    figure.effect-apollo:hover figcaption::before {
        -webkit-transform: scale3d(2.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,100%,0) !important;
        transform: scale3d(2.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,100%,0) !important;
    }

    figure.effect-apollo {
        background: $verde !important; 
    }

    figure.effect-apollo:hover .effect-apollo__img-fundo {
        opacity: .2 !important;
    }

    .grid__content {
         
    }

    figure.effect-apollo p {
        border-right: 0 !important;
        height: 100% !important;
        margin: 0 !important;
        max-width: none !important;
        overflow: visible;
        padding: 50px !important;
        width: 100% !important;

        
        display: flex !important;
        align-items: center !important;
        flex-direction: column !important;
        justify-content: center !important;
        flex-wrap: nowrap !important;
        

        @media #{$mq-mobile} {
            padding: 25px !important;
            text-align: center !important;
        }

        .subtitulo1 {
            font-size: 18px !important;
            font-weight: $f-bold;
            width:135%;
            text-align: center;
        }

        .subtitulo2 {
            font-size: 13px;
            font-weight: $f-regular;
            margin-top: 10px;
            width:100%;
            text-align: center;

            @media #{$mq-mobile} {
                font-size: 16px;
            }
        }
    }

    .effect-apollo__img {
        height: auto !important;
        margin-bottom: 30px;
        min-height: inherit !important;
        opacity: 1 !important;
        width: auto !important;

        @media #{$mq-mobile} {
            margin-bottom: 10px;
        }
    }

    .effect-apollo__img:hover {
        opacity: 1 !important;
    }

    .grid {
        -webkit-transition: all 1.8s !important;
        -moz-transition: all 1.8s !important;
        -o-transition: all 1.8s !important;
        transition: all 1.8s;
    }

    // .effect-apollo {
    //     opacity: 0;
    //     -moz-transition: all 1.8s;
    //     -webkit-transition: all 1.8s;
    //     transition: all 1.8s;
    // }

    // .effect-apollo.effect-opacity {
    //     opacity: 1 !important;
    //     -moz-transition: all 1.8s;
    //     -webkit-transition: all 1.8s;
    //     transition: all 1.8s;
    // }


/*-------------------------------
        Estrututa da empresa
-------------------------------*/ 

.home-estrutura__wrap {
    display: table;
    padding-top: 80px;
    width: 100%;

    .titulo-padrao {
        img {
            background: #fff;
        }
    }
}

/*-------------------------------
        Estrututa da empresa
-------------------------------*/ 

.home-escolha__wrap {
    background-image: url(/app/assets/images/fundo-escolha.jpg);
    margin-top: 120px;
    min-height: 600px;
    width: 100%;

    .titulo-padrao {
        padding-top: 100px;
        img {
            background: #324554
        }
    }

    .home-escolha__conteudo {
        display: flex;
        align-items: center;
        justify-content: space-around;

        @media #{$mq-tablet} {
            flex-wrap: wrap;
        }

        @media #{$mq-mobile} {
            flex-wrap: wrap;
        }
    }

        .home-escolha__item {
            display: flex;
            align-items: center;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;

            @media #{$mq-tablet} {
                width: 50%;
                margin-bottom: 50px;
                padding-bottom: 60px;
            }

            @media #{$mq-mobile} {
                width: 50%;
                margin-bottom: 50px;
                padding-bottom: 60px;
            }
        }

            .home-escolha__circle {
                border-radius: 50%;
                border: 2px solid $cinza-escuro;
                height: 130px;
                margin-bottom: 35px;
                width: 130px;

                display: flex;
                align-items: center;
                justify-content: center;
            }

            .home-escolha__contador {
                color: $branco;
                font-size: 60px;
                font-weight: $f-bold;
            }

            .home-escolha__title {
                color: $branco;
                font-size: 18px;
                font-weight: $f-regular;

                @media #{$mq-tablet} {
                    font-size: 22px;
                }

                @media #{$mq-mobile} {
                    font-size: 22px;
                }
            }
        }

        .home-escolha__contador {
            opacity: 0;
            transition: opacity .6s linear;
        }
        
        .visible-number {
            opacity: 1;
        }



/*-------------------------------
        AREAS DE ATUAÇÃO
-------------------------------*/ 

.home-fale-conosco__wrap {
    background-image: url(/app/assets/images/fundo-fale-conosco.jpg);
    min-height: 300px;
    margin-top: 100px;
    width: 100%;

    @media #{$mq-mobile} {
        background-position: center;
        background-size: cover; 
    }
    
    .titulo-padrao {
        min-height: 300px;

        img {
            background: #233645;
        }
        
        .titulo-padrao__imagem {
            margin-bottom: 35px;
        }
    }

    .home-fale-conosco__button {
        background: $verde;
        border-radius: 10px;
        color: $branco;
        cursor: pointer;
        font-size: 14px;
        font-weight: $f-bold;
        height: 31px;
        width: 110px;

        display: flex;
        align-items: center;
        justify-content: center;

        @media #{$mq-mobile} {
            font-size: 18px;
            height: 45px;
            width: 140px;
        }

        &:hover {
            background: $azul;
            transition: all .2s;
        }
    }
}

/*-------------------------------
            Processos
-------------------------------*/ 

.home-processos__wrap {
    display: table;
    padding-top: 100px;
    width: 100%;

    .titulo-padrao {
        img {
            background: $branco;
        }
    }
}


/*-------------------------------
        Confecção
-------------------------------*/ 

.home-confeccao__wrap {
    display: table;
    padding-top: 100px;
    width: 100%;

    .titulo-padrao {
        img {
            background: $branco;
        }
    }
}


/*-------------------------------
        Sustentabilidade
-------------------------------*/ 

.home-sustentabilidade__wrap {
    background: #f4f7f9;
    display: table;
    margin-top: 100px;
    padding-top: 80px;
    padding-bottom: 50px;
    width: 100%;

    .titulo-padrao {
        img {
            background: #f4f7f9;
        }
    }
}

/*-------------------------------
        Nossas Lojas
-------------------------------*/ 

.home-lojas__wrap {
    display: table;
    height: auto;
    min-height: 480px;
    padding-top: 80px;
    padding-bottom: 50px;
    width: 100%;

    .titulo-padrao {
        img {
            background: #f4f7f9;
        }
    }

    .lojas__conteudo p {
        color: $azul;
        font-size: 18px;
        font-weight: 500;
        margin-top: 20px;

        span {
            font-weight: 700;
        }

        @media #{$mq-mobile} {
            font-size: 12px;
            margin-top: 25px;
        }
    }
}


/*-------------------------------
        Entre em contato
-------------------------------*/ 

.home-contato__wrap {
    display: table;
    margin-top: 100px;
    padding-bottom: 50px;
    width: 100%;

    .titulo-padrao {
        img { 
            background: $branco;
        }
    }

    .titulo-padrao__imagem {
        margin-bottom: 35px;
    }

    .home-carrossel-padrao__sub-titulo {
        font-size: 24px;
    }
}

    .home-contato__conteudo {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        @media #{$mq-tablet} {
            div {
                width: 100%;
                
            }

            //flex-wrap: wrap;
        }

        @media #{$mq-mobile} {
            flex-wrap: wrap;
        }
    }

        .home-contato__texto {
            margin-right: 100px;

            @media #{$mq-mobile} {
                margin: 0;
                margin-bottom: 35px;
                width: 100%;
            }

            h2 {
                color: $azul;
                font-size: 18px;
                font-weight: $f-semi-bold;
                margin-bottom: 20px;
                white-space: nowrap;

                @media #{$mq-mobile} {
                    font-size: 22px;
                }
            }
            
            h3 {
                color: #73828d;
                font-size: 13px;
                font-weight: $f-regular;
                
                @media #{$mq-mobile} {
                    font-size: 18px;
                }

                p {
                    color: #73828d;
                }
            }
        }

        .home-contato__form {

            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap;

            h2 {
                color: $azul;
                font-size: 24px;
                font-weight: $f-bold;
                margin-bottom: 20px;
                width: 100%;
            }
        }
            form {
                width: 100%;
            }

                .home-contato__formulario {
                    margin-top: 15px;
                    width: 100%;

                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    

                    .home-contato__input {
                        margin-bottom: 25px;
                        width: 48%;

                        display: flex;
                        align-items: flex-start;
                        flex-direction: column;
                        flex-wrap: wrap;
                        justify-content: flex-start;

                        &:first-child {
                            margin-right: 4%;
                        }

                        label {
                            color: #73828d;
                            font-size: 13px;
                            font-weight: $f-regular;
                            margin-bottom: 5px;

                            @media #{$mq-mobile} {
                                font-size: 18px;
                            }
                        }
                        
                        input {
                            border-bottom: 1px solid $cinza;
                            border-right: 1px solid $cinza;
                            color: #73828d;
                            font-size: 13px;
                            font-weight: $f-regular;
                            height: 25px;
                            padding-left: 10px;
                            width: 100%;

                            @media #{$mq-mobile} {
                                font-size: 18px;
                                height: 45px;
                            }
                        }

                    }

                    .home-contato__mensagem {
                        margin-bottom: 25px;
                        width: 100%;

                        display: flex;
                        align-items: flex-start;
                        flex-direction: column;
                        flex-wrap: wrap;
                        justify-content: flex-start;

                        label {
                            color: #73828d;
                            font-size: 13px;
                            font-weight: $f-regular;
                            margin-bottom: 5px;

                            @media #{$mq-mobile} {
                                font-size: 18px;
                            }
                        }
                        
                        textarea {
                            border-bottom: 1px solid $cinza;
                            border-right: 1px solid $cinza;
                            color: #73828d;
                            font-size: 13px;
                            font-weight: $f-regular;
                            padding: 10px;
                            width: 100%;

                            @media #{$mq-mobile} {
                                font-size: 18px;
                                height: 100px;
                            }
                        }
                    }
                }

                .home-contato__button {
                    background: $branco;
                    cursor: pointer;
                    float: right;
                    font-size: 16px;

                    @media #{$mq-mobile} {
                        font-size: 22px;
                    }

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        margin-right: 10px;
                    }
                }

                
/*------------------------------------*\
  #VÍDEO NA HOME
\*------------------------------------*/
.home_video__wrap {
    margin-top: 60px;
    width: 100%;
}

    .home_video {
        height: 647px;
        margin-bottom: 20px;
        width: 1150px;

        @media #{$mq-tablet} {
            height: 511px;
            width: 908px;
        }

        @media #{$mq-mobile} {
            height: 338px;
            margin-top: 20px;
            width: 600px;
        }
    }



/*-------------------------------
            MAPA
-------------------------------*/ 

.home-mapa__wrap {
    height: auto;
    display: table;
    margin-bottom: -5px;
    width: 100%;

    img {
        width: 100%;
    }
}



/*-------------------------------
        MODAL IMAGENS
-------------------------------*/ 

.modal-imagens {
    display: none; 
    position: fixed; 
    z-index: 9999; 
    padding-top: 50px; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: auto; 
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.6); 

    @media #{$mq-mobile} {
        background-color: rgba(0,0,0,0.8); 
        padding-top: 50%; 
    }
}

.modal-imagens__content {
    color: #ffffff;
    margin: 0 auto;
    width: 70%;
    
    .modal-imagens__seta {
        margin: 0 auto;
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        width: 100%;

        display: flex;
        justify-content: space-between;

        @media #{$mq-mobile} {
            display: none;
        }

        img {
            margin: 0 80px;
            width: 5%;
            cursor: pointer;
        }
    }

    img {
        width: 99%;
        height: auto;

        @media #{$mq-mobile} {
            width: 100%;
        }
        
    }

    span {
        color: #ffffff;
        font-size: 40px;
        position: absolute;
        right: 17vw;

        @media #{$mq-mobile} {
            font-size: 60px;
            right: 7vw;
            top: 23vh; 
        }
        
    }

    @media #{$mq-mobile} {
        width: 90%;
    }

}


.modal-imagens__close {
    color: #aaaaaa;
    font-size: 28px;
    font-weight: bold;
}

.modal-imagens__close:hover,
.modal-imagens__close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}