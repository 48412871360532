.footer__wrap {
    background: $verde;
    border-top: 5px solid $azul;
    padding-top: 25px;
    padding-bottom: 25px;
    width: 100%;
}

    .footer__conteudo {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media #{$mq-mobile} {
            flex-wrap: wrap;
        }
    }
    
    .footer__item {
        height: 100%;
        
            @media #{$mq-mobile} {
                width: 48%;
                margin-bottom: 30px;
            }
        }

            .footer__item-logo {
                
            }

            .footer__item-texto {
                border-top: 1px solid $branco;
                color: $branco;
                font-size: 13px;
                font-weight: $f-regular;
                margin-top: 20px;
                padding-top: 20px;

                @media #{$mq-mobile} {
                    font-size: 18px;
                }
                
            }

            .footer__newsletter {
                color: $branco;
                font-size: 14px;
                font-weight: $f-bold;

                @media #{$mq-mobile} {
                    font-size: 20px;
                }
            }

            .footer__newsletter-form {
                position: relative;

                input {
                    background: transparent;
                    border-radius: 3px;
                    border: 2px solid $branco;
                    color: $branco;
                    font-size: 13px;
                    height: 45px;
                    padding-left: 10px;
                    margin-top: 20px;
                    width: 270px;

                    @media #{$mq-mobile} {
                        font-size: 18px;
                    }
                }
                
                input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: $branco;
                    font-size: 13px;
                    opacity: 1; /* Firefox */

                    @media #{$mq-mobile} {
                        font-size: 18px;
                    }
                  }
                  
                  input:-ms-input-placeholder { /* Internet Explorer 10-11 */
                    color: $branco;
                    font-size: 13px;

                    @media #{$mq-mobile} {
                        font-size: 18px;
                    }
                  }
                  
                  input::-ms-input-placeholder { /* Microsoft Edge */
                    color: $branco;
                    font-size: 13px;

                    @media #{$mq-mobile} {
                        font-size: 18px;
                    }
                  }

                button {
                    background: transparent;
                    cursor: pointer;
                    height: 35px;
                    position: absolute;
                    right: 0;
                    top: 25px;
                    width: 45px;

                    align-items: center;
                    display: flex;
                    justify-content: center;
                   
                    @media #{$mq-mobile} {
                        right: 20px;
                    }
                }
            }
        
        .footer__seguranca {
            margin-top: 30px;

            img {
                &:first-child {
                    margin-right: 20px;
                }
            }

            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        .footer__contato {
            h4 {
                color: $branco;
                font-size: 14px;
                font-weight: $f-bold;
                margin-bottom: 15px;

                @media #{$mq-mobile} {
                    font-size: 20px;
                }
            }
        }

        .footer__contato-linha {
            margin-bottom: 12px;

            display: flex;
            align-items: center;
            justify-content: flex-start;

            a {
                &:hover {
                    text-decoration: underline;
                }
            }

            p, a {
                color: $branco;
                font-size: 13px;
                font-weight: $f-regular;

                @media #{$mq-mobile} {
                    font-size: 18px;
                }
            }
        }
            .footer__contato-atendimento {
                margin-left: 30px;
            }
                
                .footer__contato-icon {
                    margin-right: 14px;
                }

            .footer__contato-social {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                a {
                    margin-right: 10px;

                    img {
                        margin-top: 10px;
                        // width: 20px;
                    }
                }
            }

            .footer__wx3 {
                border-left: 1px solid $azul;
                padding-left: 10px;
                p {
                    color: $branco;
                    font-size: 13px;
                    font-weight: $f-regular;
                    margin-bottom: 10px;

                    @media #{$mq-mobile} {
                        font-size: 18px;
                    }
                }
            }

            .footer__direitos {
                border-left: 1px solid $azul;
                margin-top: 60px;
                padding-left: 10px;

                p {
                    color: $azul;
                    font-size: 13px;
                    font-weight: $f-regular;
                    margin-bottom: 10px;

                    @media #{$mq-mobile} {
                        font-size: 18px;
                    }
                }
            }

            @media #{$mq-tablet} {
                .footer__item {
                    margin-bottom: 35px;
                    width: 50%;
                }
    
                .footer__conteudo {
                    flex-wrap: wrap;
                    
    
                    .footer__item-texto {
                        display: table;
                    }
                }
                
                .footer__newsletter-form {
                    display: table;
                    width: auto;
                }
            }



            // .footer__row {
            //     @media #{$mq-tablet} {
            //         width: 100%;
                    
            //         .footer__item {
            //             margin-bottom: 40px;
            //             width: auto;
            //             display: table;
            //         }
            //     }
            // }


            