//variáveis
$preto: #000000;
$branco: #ffffff;
$azul: #062033;
$azul-claro: #32b0ee;
$cinza: #e4e4e4;
$cinza-escuro: #dbdbdb;

$verde: #0eb493;

//fonte
$fonte-padrao: "Open Sans";
$fonte-auxiliar: "Montserrat";

$f-light: 300;
$f-regular: 400;
$f-semi-bold: 600;
$f-bold: 700;
$f-extra-bold: 800;

$fonte-tamanho-padrao: 15px;
$fonte-tamanho-mobile: 27px;

//caminho pra imagem
$caminho: "/app/assets/images";

//media queries
$mq-tablet: "(max-width: 1199px)";
$mq-mobile: "(max-width: 640px)";
