@charset "UTF-8";
@import "variaveis";


* {
  border: none;
  font-family: $fonte-padrao, Arial, Verdana, sans-serif;
  font-size: 100%;
  margin: 0;
  padding: 0;
  webkit-box-sizing: border-box;
  moz-box-sizing: border-box;
  box-sizing: border-box;
}

html, body, main, header, footer {
  width: 100%;
}

html{
  scroll-behavior: smooth;
}

main {
  background-repeat: repeat;
  display: block;
  margin-top: 0;
}

.conteudo {
  float: initial;
  margin: 0 auto;
  padding-left: 25px;
  padding-right: 25px;
  position: relative;
  width: 1200px;
  
  @media #{$mq-tablet}{
    width: 100%;
  }

  @media #{$mq-mobile} {
    padding-left: 20px;
    padding-right: 20px;
    width: 640px;
    margin: 0;
  }
}

.conteudo--full {
    float: initial;
    margin: 0 auto;
    width: 96vw;
}

body {
  background-color: #ffffff;
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

figure {
margin: 0;
}

h1, h2, h3, h4, h5, h6 {
font-family: $fonte-padrao;
margin: 0;
}

p {
  color: #fff;
  margin-bottom: 0;
  text-rendering: auto !important;
}

*:before,*::before,*:after,*::after {
clear: both;
display: inline-block;
position: absolute;
}

::-webkit-input-placeholder {
color: #000;
}

:-moz-placeholder {
color: #000;
}

::-moz-placeholder {
color: #000;
}

:-ms-input-placeholder {
color: #000;
}

b{
  font-weight: bolder;
}

a{
  text-decoration: none;
}

button{
  &:active, &:visited, &:focus{
    outline: none;
  }
}

.proxima{
  width: 35px;
  height: 35px;
  background-image: url(/app/assets/images/proxima.png);
  margin: 29px auto;
  display: block;
  background-size: contain;
}


.owl-nav{
  bottom: 20px;
  left: 50%;
  margin-left: -125px;
    
  @media #{$mq-mobile}{
    margin-left: -150px;
    width: 300px;
  }

  div{
    background-color: transparent!important;
    cursor: pointer!important;
    display: inline;
    width: 115px;

    @media #{$mq-mobile}{
      width: 130px;
    }

    span{
      font-size: 0;
    }

    &.owl-prev{

        &:before{
          content: "";
          width: 26px;
          height: 26px;
          position: relative;
          background: url(/app/assets/images/seta-anterior.png);
          background-size: contain;
          left: -22px;
          top: 1px;

          @media #{$mq-mobile}{
            left: -27px;
          }
        }

        &:after{
          content: "ANTERIOR";
          position: relative;
          left: 20px;
          font-size: 13px;
          color: $azul;
          font-weight: 600;
          top: -7px;
          left: 12px;

          @media #{$mq-mobile}{
              font-size: 20px;
              left: 12px;
              top: -5px;
          }
        }

    }

    &.owl-next{

        &:before{
          content: "SEGUINTE";
          position: relative;
          font-size: 13px;
          color: $azul;
          font-weight: 600;
          top: -7px;
          left: -9px;

          @media #{$mq-mobile}{
            font-size: 20px;
            top: -6px;
          }
        }

        &:after{
          content: "";
          width: 26px;
          height: 26px;
          position: relative;
          background: url(/app/assets/images/seta-seguinte.png);
          background-size: contain;
          right: -22px;

          @media #{$mq-mobile}{
            right: -27px;
          }
        }
    }
  }
}

.owl-nav.disabled{
  display: none;
}

.owl-stage-outer{
  overflow: hidden;
  width: 100%;
  display: block;
}

.owl-stage{
  display: flex;
}

.owl-item{
  display: flex;
  justify-content: center;
  align-items: center;
}

// ----------------------

// Estrutura padrão carrossel

.home-carrossel-padrao__sub-titulo {
    color: $azul;
    font-size: 18px;
    font-weight: $f-semi-bold;
    margin-bottom: 80px;
    text-align: justify;
    width: 100%;
}

.home-carrossel-padrao__item {

    &:hover .home-carrossel-padrao__item-titulo h2 {
        color: $verde !important;
    }

    // Cada imagem do grid
    .grid {
        max-width: 100% !important;
        padding: 0;

        display: flex;
        align-items: center;
        justify-content: center;

  
        figure {
            background: $verde;
            height: auto !important;
            margin: 0 !important;
            max-height: auto !important;
            min-width: 100%;
            overflow: hidden;

            p {
                border: 0;
                width: 100%;
                margin: auto;
                padding: 0;
                position: relative !important;
                

                & > img {
                  width: 23%; 
                  height: auto;
                  margin: 0 auto;
                  opacity: 1;
                }


                span {
                    display: table;
                    font-size: 16px;
                    font-weight: $f-regular;
                    margin-top: 15px;
                }

                .grid__social-icons {
                    bottom: 0;
                    position: absolute;
                    width: 100%;
                    
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    
                    a {
                        background: #0b7964;
                        height: 35px;
                        margin: 2.5px;
                        width: 35px;

                        &:hover {
                            background: #045042;
                        }

                        align-items: center;
                        display: flex;
                        justify-content: center;

                        img {
                            height: initial;
                            min-height: initial;
                            opacity: 1;
                            transform: none;
                            width: initial;

                        }
                    }
                }
            }

            img {
                opacity: 1;
                width: 100%;
            }

            figcaption {
                align-items: flex-start;
                display: flex;
                justify-content: space-between;
                padding: 15px;
                flex-direction: column;
            }
        }
    }

    .home-carrossel-padrao__item-titulo {
        display: flex;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;

        h2 {
            color: $azul;
            font-size: 18px;
            font-weight: $f-bold;
            margin-top: 10px;

            @media #{$mq-mobile} {
                font-size: 22px;
            }
        }

        h3 {
            color: #73828d;
            font-size: 13px;
            font-weight: $f-regular;
            margin-top: 10px;

            @media #{$mq-mobile} {
                font-size: 18px;
            }
        }
    }
}


.js-carrossel-empresa, .js-carrossel-padrao {
    height: auto;
    width: 100%;

    .owl-dots {
        margin-top: 0;
        padding-top: 90px;

        align-items: center; 
        display: flex;
        justify-content: center;
        
        span {
            background: $branco !important;
            border: 1px solid $azul;
            margin: 0 5px !important;
        }
    }

    .owl-dot.active {
        span {
            background: $azul !important;
        }
    }
}

// ----------------------


.sem-scroll{
  overflow: hidden!important;
  
  @media #{$mq-tablet}{
    overflow-x: scroll;
  }

  @media #{$mq-mobile} {
      
  }
}

.remodal-close.remodal-close.remodal-close {
  @media #{$mq-mobile} {
    width: 50px;
    height: 50px;

    &:before{
      font-size: 57px;
      line-height: 49px;
      width: 47px;
      cursor: pointer;
    }
  }
}

.box-loader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-image: url(/img/preloading.gif);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70px 70px;
  /* top: 50%; */
  /* margin-top: -55px; */
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.9803921568627451);
}

.remodal-close{
  cursor: pointer!important;
}

.titulo-padrao {
    width: 100%;

    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
        color: $verde;
        font-size: 28px;
        font-weight: $f-extra-bold;
        text-transform: uppercase;
        margin-bottom: 15px;

        @media #{$mq-mobile} {
            font-size: 34px;
        }
    }

    .titulo-padrao__imagem {
        margin-bottom: 60px;
        position: relative;
        width: 230px;

        align-items: center;
        display: flex;
        flex-direction: column;
        
        &:after {
            content: " ";
            background: $cinza-escuro;
            height: 2px;
            top: 50%;
            width: 100%;
        } 

        img {
            padding: 0 8px;
            z-index: 1;
        }
    }
}

// Ajuste duke effect carrossel
figure.effect-duke p {
    position: initial !important;
}


// owl-item effect
.owl-item {
    opacity: 0;
    transition: opacity .6s;
}

.owl-item.active {
    opacity: 1;
}

.home-banner__wrap {
    .owl-item {
        opacity: 1;
    }
}

// .effect-apollo {
//     display: none !important;
//     opacity: 0 !important;
//     transition: opacity 3s linear;
// }

// .current {
//     transition: opacity 4s;
//     .effect-apollo {
//         display: block !important;
//         opacity: 1 !important;
//         transition: opacity 4s;
//     }
// }
