/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 * 	Default theme - Owl Carousel CSS File
 */
 .owl-theme .owl-nav {
    margin-top: 10px;
    text-align: center;
    -webkit-tap-highlight-color: transparent; }
    .owl-theme .owl-nav [class*='owl-'] {
      color: #FFF;
      font-size: 14px;
      margin: 5px;
      padding: 4px 7px;
      background: #D6D6D6;
      display: inline-block;
      cursor: pointer;
      border-radius: 3px; }
      .owl-theme .owl-nav [class*='owl-']:hover {
        background: #869791;
        color: #FFF;
        text-decoration: none; }
    .owl-theme .owl-nav .disabled {
      opacity: 0.5;
      cursor: default; }
  
  .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 10px; }
  
  .owl-theme .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent; }
    .owl-theme .owl-dots .owl-dot {
      display: inline-block;
      zoom: 1;
      display: inline; }
      .owl-theme .owl-dots .owl-dot span {
        width: 10px;
        height: 10px;
        margin: 5px 7px;
        background: #D6D6D6;
        display: block;
        -webkit-backface-visibility: visible;
        transition: opacity 200ms ease;
        border-radius: 30px; }
      .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
        background: #869791; }
  